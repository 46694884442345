<template>
  <v-row justify="center">
    <!-- // If user is paid, then allow user to ask -->
    <v-dialog v-model="dialog" persistent max-width="600px" v-if="user.subsc[0]">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" class="mx-3" small>CHIEDI ORA</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Chiedi a {{speaker.first_name}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="input-7-1"
                  label="La tua domanda o osservazione *"
                  hint="Per piacere cerca di essere il piú conciso e chiaro possibile."
                  v-model="question"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small class="pl-2">*campi obbligatori</small>
          <small class="body-2 ml-5 red--text" v-if="feedbackQuestion">{{feedbackQuestion}}</small>
        </v-card-text>
        <v-card-actions>
          <p class="caption pl-5 grey--text">Utente: {{user.email}}</p>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="clear">Chiudi</v-btn>
          <v-btn color="primary" @click="submit">Chiedi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- // If user is not paid, then ask user to upgrade -->
    <v-dialog v-model="dialog" persistent max-width="600px" v-if="!user.subsc[0]">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" class="mx-3" small>CHIEDI ORA</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Fai l'upgrade per attivare questa funzionalità</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>La TechSoup Together é una community che cresce con te e il tuo contributo fa la differenza. <br> Per fare domande allo speaker é necessario essere abbonati.</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="ts_dark_grey" text @click="clear">Chiudi</v-btn>
          <UpgradeButton />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="success" top right>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { fb, questionsCollection } from "@/plugins/firebase.js";

export default {
  name: "AskTheSpeaker",

  components: {
    UpgradeButton: () => import("@/components/UpgradeButton"),
  },

  data: () => ({
    dialog: false,
    question: "",
    feedbackQuestion: "",
    snackbar: false,
    snackbarText: "Domanda inviata con successo",
    snackbarTimeout: 5000,
  }),

  props: {
    speaker: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  methods: {
    async submit() {
      if (this.user && this.question) {
        try {
          let questionDoc = await questionsCollection.add({
            content: this.question,
            userId: this.user.id,
            speakerId: this.speaker.id,
            created_at: fb.firestore.FieldValue.serverTimestamp(),
          });
          this.feedbackQuestion = "";
          this.question = "";
          this.dialog = false;
          this.snackbar = true;

          // GA4 event: add goal
          this.$gtag.query('event', 'question_ask', {
            'speaker_id': this.speaker.id,
            'speaker_slug': this.speaker.slug,
            'question_id': questionDoc.id,
          });
          
        } catch (error) {
          console.log(error);
        }
      } else {
        this.feedbackQuestion = "please fill all required info";
      }
    },
    clear() {
      this.question = null;
      this.feedbackQuestion = null;
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>