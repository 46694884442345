var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[(_vm.user.subsc[0])?_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v("CHIEDI ORA")])]}}],null,false,300501014),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Chiedi a "+_vm._s(_vm.speaker.first_name))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"input-7-1","label":"La tua domanda o osservazione *","hint":"Per piacere cerca di essere il piú conciso e chiaro possibile.","required":""},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}})],1)],1)],1),_c('small',{staticClass:"pl-2"},[_vm._v("*campi obbligatori")]),(_vm.feedbackQuestion)?_c('small',{staticClass:"body-2 ml-5 red--text"},[_vm._v(_vm._s(_vm.feedbackQuestion))]):_vm._e()],1),_c('v-card-actions',[_c('p',{staticClass:"caption pl-5 grey--text"},[_vm._v("Utente: "+_vm._s(_vm.user.email))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.clear}},[_vm._v("Chiudi")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v("Chiedi")])],1)],1)],1):_vm._e(),(!_vm.user.subsc[0])?_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v("CHIEDI ORA")])]}}],null,false,300501014),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Fai l'upgrade per attivare questa funzionalità")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("La TechSoup Together é una community che cresce con te e il tuo contributo fa la differenza. "),_c('br'),_vm._v(" Per fare domande allo speaker é necessario essere abbonati.")])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"ts_dark_grey","text":""},on:{"click":_vm.clear}},[_vm._v("Chiudi")]),_c('UpgradeButton')],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"color":"success","top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("OK")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }